<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'resourceblockgrid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'resourceblock',
			modelColumnDefs: {
				days: {
					render: (data, type, row, meta) => {
						if (data) {
							let el = this;
							const dayIds = JSON.parse(data);
							if (dayIds) {
								this.$moment.locale(window.localStorage.getItem('pui9_lang'));
								return this.$moment.weekdays(true).filter((d, index) => dayIds.includes(index));
							}
						} else {
							return '';
						}
					}
				},
				startdate: {
					render: (data, type, row, meta) => {
						return this.$dateTimeUtils.getLocalFormattedDate(data, 'DD/MM/YYYY');
					}
				},
				enddate: {
					render: (data, type, row, meta) => {
						return this.$dateTimeUtils.getLocalFormattedDate(data, 'DD/MM/YYYY');
					}
				},
				starttime: {
					render: (data, type, row, meta) => {
						if (data) {
							return this.$moment(data).format('YYYY-MM-DD HH:mm:ss').substring(11, 16);
						} else {
							return '';
						}
						return '';
					}
				},
				endtime: {
					render: (data, type, row, meta) => {
						if (data) {
							return this.$moment(data).format('YYYY-MM-DD HH:mm:ss').substring(11, 16);
						} else {
							return '';
						}
					}
				}
			}
		};
	},
	computed: {}
};
</script>
